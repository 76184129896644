import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`FOR THE LOVE OF GOD (OR WHOMEVER YOU PRAY TO)`}</strong></p>
    <p>{`Describe the `}<strong parentName="p">{`ERROR`}</strong>{` (share stacktraces (error log), and the steps you did to get there) and not just say that `}<em parentName="p">{`"I got an error"`}</em>{` if you expect `}<strong parentName="p">{`anyone`}</strong>{` to help you...`}</p>
    <h3>{`Examples of not so great statements`}</h3>
    <ul>
      <li parentName="ul">{`I have an issue`}</li>
      <li parentName="ul">{`It's not working`}</li>
      <li parentName="ul">{`It's broken`}</li>
      <li parentName="ul">{`Something is wrong`}</li>
      <li parentName="ul">{`I get an error`}</li>
    </ul>
    <p>{`Saying something like this is as useful as stating that the sky is blue...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      